<template>
  <div>
    <div class="flix-form-group">
      {{ $t('message.appointmentFormat') }}: <b>{{ getGroupTxt(client) }}</b>
      <br />
      <a
        href="#"
        class="flix-btn flix-btn-default flix-btn-xs"
        @click.prevent="edit = !edit"
        >{{
          $t('message.edit', { name: $tc('message.applicantsCount', 2) })
        }}</a
      >
    </div>
    <transition name="flixFadeIn">
      <div v-if="edit" :key="edit">
        <div class="flix-input-group" style="max-width: 500px" v-if="!check">
          <span class="flix-input-group-addon">
            <flixIcon id="group" v-if="group > 1" />
            <flixIcon id="user" v-else />
          </span>
          <input type="number" v-model="group" class="flix-form-control" />
          <span class="flix-input-group-btn">
            <a
              href="#"
              class="flix-btn flix-btn-success"
              @click.prevent="check = true"
              >{{ $t('message.save', { name: '' }) }}</a
            >
          </span>
        </div>
        <div v-if="check">
          <checkGroup
            :user="user"
            :client="client"
            :group="Number(group)"
            :callback="
               () => {
                check = false
              }
            "
          />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    checkGroup() {
      return import('./check.vue')
    }
  },
  props: {
    client: Object,
    user: String
  },
  data() {
    return {
      group: Number(this.client.group),
      edit: false,
      check: false
    }
  },
  methods: {
    getGroupTxt(client) {
      return this.$getGroupTxt(client, this)
    }
  }
}
</script>
